<template>
  <div class="o-utils-scroll-up">
    <Button css-class="button-scroll-up" aria-label="To Top" @click="clickHandler">
      <i class="icon-main-up"></i>
    </Button>
  </div>
</template>

<script setup lang="ts">
// Components
import Button from '@ice-products-ui/vue-library/Button';

// Composables
import useScroll from '~/composables/dom/useScroll';

useScroll(600, 'button-up', 100);

defineOptions({
  name: 'OUtilsScrollUp',
});

const clickHandler = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};
</script>
